import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14daf630"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wizard-step-one-block__title-badge"
}
const _hoisted_2 = { class: "headline-18 lh-24 mb-2" }
const _hoisted_3 = { class: "lh-20 mb-6 mr-n3" }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = {
  key: 0,
  class: "step-list"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 1,
  class: "mt-6"
}
const _hoisted_8 = { class: "body-text-semi-bold-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wizard-step-one-block", {
      'wizard-step-one-block--has-badge': _ctx.titleBadge
    }])
  }, [
    (_ctx.titleBadge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.titleBadge), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_icon, {
      name: _ctx.icon,
      class: "primary--text mb-3",
      "custom-size": "32px"
    }, null, 8, ["name"]),
    _renderSlot(_ctx.$slots, "title", {}, () => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ], true),
    _renderSlot(_ctx.$slots, "description", {}, () => [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.description), 1)
    ], true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.list)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                class: "d-flex"
              }, [
                _createVNode(_component_tm_icon, {
                  name: "tmi-check-bolder",
                  class: "step-list__icon",
                  size: "xLarge"
                }),
                _createElementVNode("div", {
                  class: "flex-grow-1",
                  innerHTML: item
                }, null, 8, _hoisted_6)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (!_ctx.hideBtn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_tm_button, {
            color: "primary",
            size: "large",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select-click')))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-366cbbcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wizard-step-block-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_step_block = _resolveComponent("wizard-step-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wizard, (item, i) => {
      return (_openBlock(), _createBlock(_component_wizard_step_block, {
        key: i,
        icon: item.icon,
        title: item.title,
        description: item.description,
        "button-text": item.buttonText,
        list: item.list,
        "title-badge": item.titleBadge,
        class: "wizard-step-block-container__block",
        onSelectClick: ($event: any) => (_ctx.$router.push(item.link))
      }, null, 8, ["icon", "title", "description", "button-text", "list", "title-badge", "onSelectClick"]))
    }), 128))
  ]))
}

import { defineComponent } from 'vue'
import WizardStepBlock from '@/components/pages/auth/wizard/WizardStepBlock.vue'

export default defineComponent({
  components: {
    WizardStepBlock,
  },
  props: {
    wizard: {
      type: Array,
    },
  },
})

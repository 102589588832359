
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { PropType } from '@vue/runtime-core'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    hideBtn: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    titleBadge: {
      type: String,
    },
    buttonText: {
      type: String,
      default: 'Select',
    },
    list: {
      type: Array as PropType<string[]>,
    },
  },
  emits: ['select-click'],
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_step_block_container = _resolveComponent("wizard-step-block-container")!
  const _component_page_step = _resolveComponent("page-step")!

  return (_openBlock(), _createBlock(_component_page_step, {
    title: "How are you planning to use Textmagic?",
    subtitle: "Please let us know if you are a business or a private user",
    steps: _ctx.wizardSteps,
    "steps-size": _ctx.stepSize,
    "current-step-index": _ctx.currentIndex
  }, {
    content: _withCtx(() => [
      _createVNode(_component_wizard_step_block_container, { wizard: _ctx.wizard }, null, 8, ["wizard"])
    ]),
    _: 1
  }, 8, ["steps", "steps-size", "current-step-index"]))
}
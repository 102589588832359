
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import WizardStepBlockContainer from '@/components/pages/auth/wizard/WizardStepBlockContainer.vue'
import { wizardSteps } from '@/definitions/auth/data'
import PageStep from '@/components/layout/PageStep.vue'

export default defineComponent({
  components: {
    PageStep,
    WizardStepBlockContainer,
  },
  props: {
    stepSize: {
      type: String,
    },
  },
  setup() {
    const currentIndex = ref(1)
    const route = useRoute()
    const country = computed(() => route.meta.country)

    const wizard = ref([
      {
        icon: 'business',
        title: 'Business / Organization',
        description: 'You represent a company or an organization and plan to use SMS for the following use cases:',
        list: [
          'Send text messages to my <strong>customers, employees and partners.</strong>',
          '<strong>SMS marketing,</strong> Email to SMS, API, Carrier lookup and/or other business services.',
        ],
        buttonText: 'Choose business use case',
        link: { name: 'wizard.' + country.value + '.smsSender' },
      },
      {
        icon: 'account_circle',
        title: 'Private user',
        description: 'You plan to use Textmagic as a private individual for your own personal use case, such as:',
        list: [
          'Send text messages to <strong>friends or family</strong> or only receive messages.',
          'Receive verification SMS (2FA) for <strong>social media accounts</strong> using virtual numbers.',
        ],
        buttonText: 'Choose private use case',
        link: { name: 'wizard.private', params: { country: country.value } },
      },
    ])

    return {
      wizardSteps,
      currentIndex,
      wizard,
    }
  },
})
